<template lang="">
  <base-layout>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
          <TitlePlus
            :title="$t('components.marketPlaceManagement.buyers.headline')"
            :hide-plus="true"
          />
          <div class="md:flex md:justify-end">
            <DateRangePicker
              :time-filter-enabled="false"
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2 my-1 md:items-center md:flex-row px-5">
        <SummaryCard
          :title="
            $t('components.marketPlaceManagement.buyers.summary.totalBuyers')
          "
          :value="indexMetaData.summary.total_number_of_buyers"
          variant="gray"
        />
        <SummaryCard
          :title="
            $t(
              'components.marketPlaceManagement.buyers.summary.totalAssignedVehicles'
            )
          "
          :value="indexMetaData.summary.total_assigned_vehicles"
          variant="gray"
        />
        <SummaryCard
          :title="
            $t(
              'components.marketPlaceManagement.buyers.summary.totalPurchasedVehicles'
            )
          "
          :value="indexMetaData.summary.total_purchased_vehicles"
          variant="gray"
        />
        <SummaryCard
          :title="
            $t(
              'components.marketPlaceManagement.buyers.summary.totalPurchasedVehiclesAmount'
            )
          "
          :value="indexMetaData.summary.total_purchased_vehicles_value"
          variant="gray"
        />
        <SummaryCard
          :title="
            $t('components.marketPlaceManagement.buyers.summary.totalRevenue')
          "
          :value="indexMetaData.summary.total_revenue"
          variant="gray"
        />
        <SummaryCard
          :title="
            $t('components.marketPlaceManagement.buyers.summary.totalPayout')
          "
          :value="indexMetaData.summary.total_payout"
          variant="gray"
        />
      </div>

      <FSTable
        :fst-id="`marketplaceAllBuyersIndex`"
        :searchEnabled="false"
        :headers="tableHeaders"
        :endpoint="getEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            text-fallback-always
          >
            <FSTableRowItem
              :text="item.full_name"
              :to="{
                name: 'ViewBuyerProfile',
                params: { id: item.id, redirectPath: path },
              }"
            />
            <FSTableRowItem :text="item.email" />

            <FSTableRowItem :text="item.last_login" :date="true" />
            <FSTableRowItem :text="item.last_purchased_at" :date="true" />
            <FSTableRowItem>
              <template v-if="item.payment_method.length > 0">
                <i :class="getPaymentCardIconClass(item.payment_method[0])" />
                <span>&nbsp;&nbsp;</span>
                {{
                  `${item.payment_method[0].brand}**${item.payment_method[0].detail}`
                }}
              </template>
              <template v-else><span>--</span></template>
            </FSTableRowItem>

            <FSTableRowItem
              :text="
                `${item.total_assigned_vehicles}/${item.number_of_vehicle_purchased}`
              "
            />
            <FSTableRowItem :text="` ${item.purchased_value}`" />
            <FSTableRowItem :text="` ${item.lifetime_revenue}`" />
            <FSTableRowItem :text="` ${item.available_funds}`" />

            <FSTableRowItem>
              <BuyerActivityStatusAction
                :id="itemIndex"
                :data="item"
                @force-sync="$store.dispatch('fsTable/fetchData')"
              />
            </FSTableRowItem>
            <FSTableRowItem>
              <AvailableFundsTransfer
                :id="itemIndex"
                :data="item"
                @force-sync="$store.dispatch('fsTable/fetchData')"
              />
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'

import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
export default {
  name: 'ViewMarketplaceBuyersIndex',
  components: {
    BaseLayout,
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    BuyerActivityStatusAction: () =>
      import('@/views/marketplace/modals/BuyerActivityStatusAction.vue'),
    AvailableFundsTransfer: () =>
      import('@/views/marketplace/modals/AvailableFundsTransfer.vue'),
    DateRangePicker,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
  },
  data() {
    return {
      path: '',
      indexMetaData: {
        count: {
          total: 0,
        },
        currency: {
          symbol: '',
        },
        summary: {
          total_number_of_buyers: 0,
          total_assigned_vehicles: 0,
          total_purchased_vehicles: 0,
          total_purchased_vehicles_value: 0,
          total_revenue: 0,
          total_payout: 0,
        },
      },
      tableHeaders: [
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.table.columns.name'
          ),
          width: '10%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.table.columns.email'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.table.columns.lastLoginAt'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.table.columns.lastPurchasedAt'
          ),
          width: '12%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.table.columns.paymentMethod'
          ),
          width: '12%',
          sort: 'null',
        },

        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.table.columns.assigned'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.table.columns.purchasedValue'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.table.columns.lifetimeRevenue'
          ),
          width: '5%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.table.columns.availableFund'
          ),
          width: '5%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.table.columns.status'
          ),
          width: '10%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.table.columns.actions'
          ),
          width: '5%',
          sort: 'null',
        },
      ],
    }
  },
  created() {
    this.path = this.$route.path
  },
  computed: {
    marketplaceId() {
      return this.$route.params.id
    },
    getEndpoint() {
      return MarketplaceConfig.api.marketplaceAllBuyerList()
    },
  },

  methods: {
    onApplyFilterDateRange,
    getPaymentCardIconClass(card) {
      const brand = card?.brand?.toLowerCase() || ''
      console.log('getPaymentCardIconClass', brand)
      let iconClass = 'fab '
      switch (brand?.toLowerCase()) {
        case 'visa':
          iconClass += 'fa-cc-visa'
          break
        case 'mastercard':
          iconClass += 'fa-cc-mastercard'
          break
        case 'americanexpress':
          iconClass += 'fa-cc-amex'
          break
        case 'discover':
          iconClass += 'fa-cc-discover'
          break
        case 'jcb':
          iconClass += 'fa-cc-jcb'
          break
        case 'dinersclub':
          iconClass += 'fa-cc-diners-club'
          break
        default:
          iconClass = 'fas fa-wallet'
          break
      }
      return iconClass
    },
  },
}
</script>
<style lang=""></style>
