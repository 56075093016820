var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-4 md:grid-cols-2"},[_c('TitlePlus',{attrs:{"title":_vm.$t('components.marketPlaceManagement.buyers.headline'),"hide-plus":true}}),_c('div',{staticClass:"md:flex md:justify-end"},[_c('DateRangePicker',{attrs:{"time-filter-enabled":false},on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1)],1)]),_c('div',{staticClass:"flex flex-col gap-2 my-1 md:items-center md:flex-row px-5"},[_c('SummaryCard',{attrs:{"title":_vm.$t('components.marketPlaceManagement.buyers.summary.totalBuyers'),"value":_vm.indexMetaData.summary.total_number_of_buyers,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t(
            'components.marketPlaceManagement.buyers.summary.totalAssignedVehicles'
          ),"value":_vm.indexMetaData.summary.total_assigned_vehicles,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t(
            'components.marketPlaceManagement.buyers.summary.totalPurchasedVehicles'
          ),"value":_vm.indexMetaData.summary.total_purchased_vehicles,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t(
            'components.marketPlaceManagement.buyers.summary.totalPurchasedVehiclesAmount'
          ),"value":_vm.indexMetaData.summary.total_purchased_vehicles_value,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.marketPlaceManagement.buyers.summary.totalRevenue'),"value":_vm.indexMetaData.summary.total_revenue,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.marketPlaceManagement.buyers.summary.totalPayout'),"value":_vm.indexMetaData.summary.total_payout,"variant":"gray"}})],1),_c('FSTable',{attrs:{"fst-id":"marketplaceAllBuyersIndex","searchEnabled":false,"headers":_vm.tableHeaders,"endpoint":_vm.getEndpoint},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var data = ref.data;
return _vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',{attrs:{"text":item.full_name,"to":{
              name: 'ViewBuyerProfile',
              params: { id: item.id, redirectPath: _vm.path },
            }}}),_c('FSTableRowItem',{attrs:{"text":item.email}}),_c('FSTableRowItem',{attrs:{"text":item.last_login,"date":true}}),_c('FSTableRowItem',{attrs:{"text":item.last_purchased_at,"date":true}}),_c('FSTableRowItem',[(item.payment_method.length > 0)?[_c('i',{class:_vm.getPaymentCardIconClass(item.payment_method[0])}),_c('span',[_vm._v("  ")]),_vm._v(" "+_vm._s(((item.payment_method[0].brand) + "**" + (item.payment_method[0].detail)))+" ")]:[_c('span',[_vm._v("--")])]],2),_c('FSTableRowItem',{attrs:{"text":((item.total_assigned_vehicles) + "/" + (item.number_of_vehicle_purchased))}}),_c('FSTableRowItem',{attrs:{"text":(" " + (item.purchased_value))}}),_c('FSTableRowItem',{attrs:{"text":(" " + (item.lifetime_revenue))}}),_c('FSTableRowItem',{attrs:{"text":(" " + (item.available_funds))}}),_c('FSTableRowItem',[_c('BuyerActivityStatusAction',{attrs:{"id":itemIndex,"data":item},on:{"force-sync":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1),_c('FSTableRowItem',[_c('AvailableFundsTransfer',{attrs:{"id":itemIndex,"data":item},on:{"force-sync":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)],1)})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }